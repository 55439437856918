<template>
  <div class="courseSelection">
    <div class="filtrate">
      <div class="filtrate-content">
        {{ titleName }}
      </div>
    </div>
    <div class="result-box">
      <ul class="result-list">
        <router-link
          :to="'/seriesLessonsInfo/' + item.data_id"
          class="result-info"
          v-for="item in list"
          :key="item.data_id"
        >
          <img class="result-img" v-lazy="item.image" :key="item.image" />
          <div class="result-content">
            <div class="result-title">{{ item.name }}</div>
            <!-- <router-link :to="'/recordedInfo/'+item.id" class="result-title">{{item.name}}</router-link> -->
            <div class="result-marks" v-if="item.course_tags">
              <div class="mark" v-for="mark in item.course_tags" :key="mark">
                {{ mark }}
              </div>
            </div>
            <div v-if="item.duration" class="result-lecturers">{{ item.duration }}课时</div>
            <div class="result-describe">{{ item.intro }}</div>
          </div>
          <div class="result-pice-paynum">
            <div class="result-pice" v-if="websiteData.is_course_pay==1">
              <span v-if="item.price != '0.00'">￥</span
              >{{ item.price == "0.00" ? "免费" : item.price }}
            </div>
            <!-- <div class="result-paynum">34564人已购</div> -->
          </div>
        </router-link>
      </ul>
      <pagination
        :num="num"
        :limit="limit"
        @getNew="getNew"
        v-if="paginationUpdata"
      ></pagination>
    </div>
  </div>
</template>
<script>
import pagination from "@/components/pc/pagination.vue";
export default {
  name: "CourseSelection",
  data() {
    return {
      paginationUpdata: true, //重新渲染分页
      pageType: 0, //1.选课中心 2.系列课
      category: [], //筛选条件
      categorySelect: {}, //选中条件
      category_id: 0, //分类id
      list: [], //课程
      num: 0, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
      titleName: "",
      module_id: "",
      websiteData:'',
    };
  },
  components: {
    pagination,
  },
  mounted() {
    this.titleName = this.$route.query.name;
    this.module_id = this.$route.query.id;
    if (this.module_id) {
      this.getModelList();
    }
    this.websiteData = JSON.parse(localStorage.getItem('websiteState'))

  },
  methods: {
    async getModelList() {
      let data = await this.$axios.post(`/v1/home/moduleData`, {
        id: this.module_id,
        page: this.nowPage,
        pre_page: this.limit,
      });
      this.list = data.data.data;
      this.num = data.data.total;
    },
    //分页
    getNew(nowPage) {
      this.nowPage = nowPage;

      this.getModelList();
    },
  },
  watch: {
    //监听路由判断换题
    $route() {
      this.$router.go(0);
    },
  },
};
</script>
<style scoped lang="scss">
.courseSelection {
  background-color: #f7f7f7;
}
.filtrate {
  width: 100%;
  background-color: #fff;
  margin-bottom: 24px;
  .filtrate-content {
    line-height: 50px;
    font-size: 20px;
    padding-left: 15px;
  }
}
.sorting {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  .drop-down-box {
    margin-right: 50px;
  }
  .has-arrow {
    font-size: 14px;
    color: #999;
    margin-right: 50px;
    min-height: 18px;
    padding-right: 12px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      width: 8px;
      height: 18px;
      background: url(~@/assets/arrows-lang-ico.png) no-repeat right center;
      background-size: auto 18px;
      position: absolute;
      right: 0;
      top: 0;
    }
    &.asc::after {
      transform: rotate(180deg);
    }
  }
  .result-sum {
    margin-left: auto;
    display: flex;
    align-items: center;
    .sum-num {
      font-size: 14px;
      color: #666;
    }
    .turn {
      width: 24px;
      height: 24px;
      background: url(~@/assets/arrows-right.png) no-repeat right center;
      background-size: 100%;
      cursor: pointer;
      &.pre {
        transform: rotate(180deg);
        margin: 0 4px;
      }
    }
  }
}
.result-box {
  padding-bottom: 88px;
}
.result-list {
  box-sizing: border-box;
  width: 1200px;
  min-height: 700px;
  padding: 24px;
  margin: 0 auto;
  background-color: #fff;
}
.result-info {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 8px;
  }
}
.result-img {
  width: 282px;
  height: 156px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}
.result-content {
  width: 578px;
  height: 156px;
  margin-left: 28px;
  position: relative;
}
.result-title {
  display: block;
  font-size: 16px;
  font-weight: 800;
  color: #333;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 10px;
  padding-top: 10px;
}
.result-marks {
  overflow: hidden;
  margin-bottom: 10px;
  .mark {
    padding: 0 8px;
    height: 18px;
    line-height: 18px;
    background: rgba(37, 78, 212, 0.14);
    box-shadow: 0px 0px 14px 0px rgba(0, 12, 40, 0.14);
    border-radius: 10px 2px 10px 2px;
    font-size: 12px;
    color: #254ed4;
    text-align: center;
    float: left;
    margin-right: 10px;
  }
}
.result-lecturers {
  font-size: 14px;
  color: #666;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 23px;
}
.result-describe {
  max-height: 48px;
  font-size: 14px;
  color: #999;
  line-height: 24px;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  //多行省略号
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: 10px;
}
.result-pice-paynum {
  margin-left: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.result-pice {
  width: 100%;
  font-size: 22px;
  font-weight: 900;
  color: #cd1b14;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  margin-bottom: 5px;
}
.result-paynum {
  width: 100%;
  font-size: 12px;
  font-weight: 900;
  color: #999;
  text-shadow: 0px 0px 14px rgba(0, 12, 40, 0.14);
  text-align: center;
}
.page-wrap {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding-bottom: 20px;
  ::v-deep .pointer.hover {
    background: none;
  }
}
</style>